<template>
    <div
        class="container-fluid core-view d-flex flex-column transfers-list"
        :class="{'menu-shown': !sideMenuHidden, 'menu-collapsed': !sideMenuHidden && sideMenuCollapsed, 'menu-expanded': !sideMenuHidden && !sideMenuCollapsed}"
        data-cy="transferList"
    >
        <List
            analytics-prefix="Transfers"
            :columns="columns"
            :companies="[]"
            :items="transfers"
            :fetching="fetching"
            :loading="loading"
            model-type="Transfers"
            text-search-key="search"
            :tooltip-wording="tooltipWording"
            @fetch="fetch"
        >
            <template #action-buttons>
                <Button
                    v-if="canManage"
                    v-b-tooltip.hover.left
                    :to="{name: 'transfer.builder.add'}"
                    data-cy="addTransferBtn"
                    class="btn-add-transfer"
                    title="Add a Transfer"
                    aria-label="Add a Transfer"
                    variant="primary"
                    block
                >
                    <FontAwesomeIcon
                        icon="plus"
                        class="btn-icon-right"
                    />

                    Transfer
                </Button>
            </template>

            <template #list-item-action-buttons="{item}">
                <RouterLink
                    v-if="canManage"
                    v-b-tooltip.hover.top
                    :to="{name: 'transfer.builder.edit', params: {transferId: item.id}}"
                    data-cy="editTransferBtn"
                    class="btn btn-link btn-admin btn-options px-2"
                    title="Edit Transfer"
                    aria-label="Edit Transfer"
                >
                    <FontAwesomeIcon
                        icon="tools"
                        class="fa-lg fa-fw"
                        fas
                    />
                </RouterLink>
            </template>
        </List>

        <RouterView
            @updated="fetch"
        />
    </div>
</template>

<script>
    import Button from '@imt/vue-kit-car/src/components/Button.vue';
    import List from '@imt/vue-list/src/components/List.vue';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import {mapActions, mapState} from 'vuex';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'PTransferList',
        components: {
            Button,
            List,
        },
        mixins: [
            authMixin,
            toastsMixin,
        ],
        data() {
            return {
                fetching: false,
                loading: true,
                tooltipWording: {
                    search_field_display: {},
                    search_field_names: {
                        search: 'Text Search by Title',
                    },
                },
                columns: [
                    {
                        class: 'col-3 col-md-3 col-lg-3 transfer-title text-break',
                        key: 'title',
                        header: 'Title',
                        sortable: true,
                        sortBy: 'title',
                        value: item => {
                            return item.title;
                        },
                    },
                    {
                        class: 'col-3 col-md-3 col-lg-3 transfer-code text-break',
                        key: 'code',
                        header: 'Code',
                        sortable: true,
                        sortBy: 'code',
                        value: item => {
                            return item.code;
                        },
                    },
                    {
                        class: 'col-3 col-md-3 col-lg-4 d-none d-md-inline d-lg-inline transfer-schedule text-break',
                        key: 'schedule',
                        header: 'Schedule',
                        sortable: false,
                        value: item => {
                            return item.schedule || 'None';
                        },
                    },
                    {
                        class: 'col-1 d-lg-inline transfer-steps',
                        key: 'steps',
                        header: 'Number Of Steps',
                        value: item => {
                            return item.steps.length;
                        },
                    },
                    {
                        class: 'col-auto options px-0 ml-auto',
                        key: 'options',
                    },
                ],
            };
        },
        computed: {
            ...mapState([
                'transfers',
            ]),
            ...mapState('toolbox', [
                'permissions',
                'sideMenuCollapsed',
                'sideMenuHidden',
            ]),
        },
        async created() {
            await this.init();
        },
        methods: {
            async init() {
                this.loading = true;
                await this.fetch();
                this.loading = false;
            },
            async fetch(query = 'include=steps') {
                this.fetching = true;

                try {
                    await this.fetchTransfers(query);
                } catch (err) {
                    /* istanbul ignore next */
                    utils.console.log(err);
                }

                this.fetching = false;
            },
            ...mapActions([
                'fetchTransfers',
            ]),
            ...mapActions('builder', [
                'deleteTransfer',
            ]),
        },
    };
</script>
